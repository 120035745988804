<template>
  <section class=" w-screen h-screen text-left ">
    <h1>Burger Menu</h1>

    <input id="burger" type="checkbox" />

    <label for="burger">
      <span></span>
      <span></span>
      <span></span>
    </label>

    <nav>
      <ul>
        <li><a href="#">Link #1</a></li>
        <li><a href="#">Link #2</a></li>
        <li><a href="#">Link #3</a></li>
      </ul>
    </nav>
  </section>

</template>

<script>

export default {
  name: "HamburgerMenuNr6",


  data() {

    return {

      open: false,

    }


  },

  methods: {

    openMenu() {

      this.open = !this.open


    }
  },


}
</script>


<style scoped lang="scss">
$blackColor: #020304;

section {
  background: $blackColor;
  h1 {
    position: fixed;
    top: 40px;
    left: 40px;
    z-index: 6;
    font-size: 20px;
    font-weight: 900;
    font-family: sans-serif;
    text-transform: uppercase;
    > span {
      text-transform: none;
      opacity: .5;
      font-weight: 300;
      font-size: 12px;
    }
  }
  input + label {
    position: fixed;
    top: 40px;
    right: 40px;
    height: 20px;
    width: 15px;
    z-index: 5;
    span {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%;
      margin-top: -1px;
      left: 0;
      display: block;
      background: $blackColor;
      transition: .5s;
    }
    span:first-child {
      top: 3px;
    }
    span:last-child {
      top: 16px;
    }
  }
  label:hover {
    cursor: pointer;
  }
  input:checked + label {
    span {
      opacity: 0;
      top: 50%;
    }
    span:first-child {
      opacity: 1;
      transform: rotate(405deg);
    }
    span:last-child {
      opacity: 1;
      transform: rotate(-405deg);
    }
  }
  input ~ nav {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 3;
    transition: .5s;
    transition-delay: .5s;
    overflow: hidden;
    > ul {
      text-align: center;
      position: absolute;
      top: 35%;
      left: 20%;
      right: 20%;
      > li {
        opacity: 0;
        transition: .5s;
        transition-delay: 0s;
        > a {
          text-decoration: none;
          text-transform: uppercase;
          color: $blackColor;
          font-weight: 700;
          font-family: sans-serif;
          display: block;
          padding: 30px;
        }
      }
    }
  }
  input:checked ~ nav {
    height: 100%;
    transition-delay: 0s;
    > ul {
      > li {
        opacity: 1;
        transition-delay: .5s;
      }
    }
  }
}

</style>